import bus from "../plugins/bus";

export default (user, router, ...roles) => {
  console.debug("PERMISSION DENIED HANDLER FIRE");
  if (!roles.includes(user.role)) {
    router.push({ name: "Dashboard" });
    bus.$emit("snackbar", {
      text: "Nedostatečné oprávnění",
      color: "error",
    });
    return false;
  }
  return true;
};
