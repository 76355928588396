<template>
  <div class="admin-container">
    <template v-if="data">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'Projecting-detail',
                params: {
                  id: $route.params.id,
                },
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Dokument</h3>
      <template v-if="!isError">
        <v-row>
          <v-col cols="12" md="3"
            ><v-text-field
              v-model="data.name"
              label="Název dokumentu *"
            ></v-text-field
          ></v-col>
          <!-- <v-col cols="12" md="3"
          ><v-select
            :items="types"
            v-model="data.section"
            label="Sekce *"
            item-text="name"
            item-value="section"
          ></v-select
        ></v-col> -->
        </v-row>

        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            :max-width="450"
            persistent
            class="rounded-0"
          >
            <v-card>
              <v-card-title class="headline">Odstranit dokument</v-card-title>
              <v-card-subtitle
                >Opravdu si přejete odstranit dokument ?</v-card-subtitle
              >
              <v-card-text>
                <v-row> </v-row>
                <v-row class="mt-6">
                  <v-col class="text-center">
                    <v-btn
                      color="primary"
                      small
                      tile
                      width="100%"
                      @click="handleDeleteFile"
                      >Odstranit</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <v-btn small text @click="dialog = false" width="100%"
                      >Zrušit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions> </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <input
          style="display: none"
          ref="documentFile"
          type="file"
          class="input--file"
          @input="handleInputFile($event.target.files[0])"
        />
        <div class="d-flex">
          <div
            v-if="data.document_id"
            class="document-added mr-6 cursor-pointer"
            @click.stop="downloadDoc(data.document_id)"
          >
            <div
              class="document-document-mini"
              :style="correctIcon(data)"
            ></div>
            <div class="document-extension">
              {{ data.extension ? data.extension.toUpperCase() : '' }}
            </div>
            <div
              class="document-text document-text--overflow document-text--name mt-2"
            >
              {{ data.original_name }}
            </div>
            <div class="document-close">
              <button @click.stop="dialog = true">
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button>
            </div>
          </div>
          <button
            class="document-input-add"
            @click="handleClick('documentFile')"
          >
            <div class="document-input-add-plus">+</div>
            <div class="document-input-add-text">Vložit dokument</div>
          </button>
        </div>
        <v-row>
          <v-col cols="12" md="3"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3"></v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="text-right">
            <v-btn
              small
              tile
              color="secondary"
              class="mr-4"
              @click="
                $router.push({
                  name: 'Projecting-detail',
                  params: {
                    id: $route.params.id,
                  },
                })
              "
              >Zrušit</v-btn
            >
            <v-btn small tile color="primary" @click="update">Uložit</v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import projectingSubdirectoryService from '../api/projectingSubdirectoryService';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import projectingDocumentService from '../api/projectingDocumentService';
import projectingService from '../api/projectingService';

const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      dialog: false,
      data: null,
      document: null,
      isError: false,
      types: [
        {
          section: 'CURRENT_PROJECT_VERSION',
          name: 'Aktuální verze projektu',
        },
      ],
    };
  },
  async created() {
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
    await this.show();
  },
  methods: {
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    async show() {
      try {
        bus.$emit('processing');
        const { data } = await projectingService.document(
          this.$route.params.documentId,
          this.$route.params.id
        );
        this.data = data;
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit('processing');
        await documentService.show(id);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async handleCamera() {
      let stream = null;

      //   const player = document.getElementById("player");
      //   const canvas = document.getElementById("canvas");
      //   const context = canvas.getContext("2d");
      //   const captureButton = document.getElementById("capture");
      //   captureButton.addEventListener("click", () => {
      //     // Draw the video frame to the canvas.
      //     context.drawImage(player, 0, 0, canvas.width, canvas.height);
      //   });
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            mandatory: {
              minWidth: 1280,
              minHeight: 720,
            },
          },
        });
        /* use the stream */
        console.debug(stream);
        // player.srcObject = stream;
        player.src = stream;

        // video.src = window.URL.createObjectURL(stream);
      } catch (err) {
        /* handle the error */
        // console.error(err);
        console.log(err.message);
        if (err.message == 'Permission denied') {
          bus.$emit('snackbar', {
            text: 'Nemáte povolený přístup k fotoaparátu.',
            color: 'error',
          });
        }
      }
    },
    async handleDeleteFile() {
      try {
        bus.$emit('processing');
        await documentService._delete(this.data.document_id);
        this.data.document_id = null;
        this.dialog = false;
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f) {
      try {
        console.debug(f);
        bus.$emit('processing');
        const { data } = await documentService.store(f);
        this.data.document_id = data.id;
        this.data.original_name = data.client_name;
        this.data.extension = data.extension;
        console.debug(data);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async update() {
      try {
        bus.$emit('processing');
        const response = await projectingDocumentService.update(
          this.$route.params.documentId,
          this.data,
          this.$route.params.id
        );
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'Projecting-detail',
          params: {
            id: this.$route.params.id,
          },
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    iconDocument() {
      return { backgroundImage: `url(${require('@/assets/document.svg')})` };
    },
  },
  watch: {},
};
</script>

<style></style>
